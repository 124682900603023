import { EnvironmentInterface, EnvironmentTypesEnum } from './env.model';

export const environment: EnvironmentInterface = {
  // eslint-disable-next-line global-require
  appVersion: require('../../package.json').version,
  apiUrl: 'https://api-dev3.sembot.ovh/api/v1',
  dataUrl: 'https://api-dev3.sembot.ovh',
  stripePublicKey: 'pk_test_51J56QlJvqs0qeOPOK20bOMhWnrTYxNHz7p32CBBDUehDAb1tYMKt6TkC1PcgCNhKq4cfUiGNvBYyTKH8UJsS0fwT00ITIzr85Y',
  domain: 'sembot.com',
  production: false,
  trustedUsers: [],
  type: EnvironmentTypesEnum.local,
  pluginMode: null,
  shopifyPublic: 'bd7b3c1d071ffbe6b1ef2a81b4472d27',
  shopifyName: 'sembot-beta',
  recaptcha: {
    siteKey: '6LeJo44mAAAAAIHVxGTeSFueDVOp__PY1s1sRrFs', // localhost, beta, dev
  },
};
